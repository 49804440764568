import React from 'react'
import Layout from '../Layout'
import View from '../View'
import EmailPreview from '../EmailPreview'
import  Moment from 'moment'
import { getCurrentUser } from '../../utils/auth'
import { getEmailTemplate } from '../../utils/email'
import { Link } from 'gatsby'

class EmailTemplate extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      to: 'example@forms-surfaces.net',
      template: {},
      user: {},
      errorMessage: ''
    }
  }

  componentDidMount() {
    var user = getCurrentUser()
    this.setState({
      user: user
    })
    getEmailTemplate({templateid: this.props.templateid}, function(err, response){
      if(err){
        this.setState({errorMessage: err.message})
      }
      if(response.template){
        if(response.template.errorMessage){
          this.setState({errorMessage: response.template.errorMessage})
        }
      }
      this.setState({template: response.template})
    }.bind(this))
  }

  render(){
    let errorMessage = ''
    if(this.state.errorMessage){
      errorMessage = <p className="error">Error: {this.state.errorMessage}</p>
    }
    let preview = ''
    if(this.state.template.subject){
      preview = <div><h3>Preview:</h3>
                <EmailPreview
                to={this.state.to}
                subject={this.state.template.subject}
                userMessage={this.state.template.userMessage}
                domain={this.state.template.domain}
                pages={this.state.template.pagesShared}
              /></div>
    }
    let composeLink = '/email/test?template=' + this.props.templateid
    let editLink = '/email/template/edit/' + this.props.templateid
    return (
      <Layout>
      <View title="EMAIL TEMPLATE"
      parent={{
        url: '/email/list-templates/',
        title: 'EMAIL TEMPLATES'
      }}>
        <ul>
          <li>Subject: {this.state.template.subject}</li>
          <li>Created: {Moment(this.state.template.created)
          .local()
          .format("MM-DD-YYYY hh:mm:ss a")}</li>
        </ul>
        {errorMessage}
        <Link className="button block" to={composeLink}>Compose Email</Link>
        <Link className="button block" to={editLink}>Edit Template</Link>
        {preview}
      </View>
      </Layout>
    )
  }

}

export default EmailTemplate
